import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  form: FormGroup;

  constructor(private fb: FormBuilder, public alertController: AlertController, private router: Router, private auth: AuthService) {
    this.form = this.fb.group({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required])
    });
  }

  ngOnInit() { }

  login() {
    if (this.form.controls.email.invalid) {
      const opts = {
        header: 'Atenção!',
        message: 'Digite um e-mail válido para poder prosseguir.',
        buttons: ['OK']
      };
      this.alertController.create(opts)
        .then(alert => {
          alert.present();
        });
    } else if (this.form.controls.password.invalid) {
      const opts = {
        header: 'Atenção!',
        message: 'É preciso informar uma senha para poder prosseguir.',
        buttons: ['OK']
      };
      this.alertController.create(opts)
        .then(alert => {
          alert.present();
        });
    } else {
      this.auth.login();
      this.router.navigateByUrl("inicio");
    }
  }
}
