import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(public alertController: AlertController, public auth: AuthService, private router: Router) { }


  logout(): void {
    const opts = {
      header: 'Desconectar',
      message: 'Você deseja sair da sua conta?',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
          cssClass: 'btn-alert-medium',
        },
        {
          text: 'Sim',
          handler: () => {
            this.auth.logout();
            this.router.navigateByUrl("login");
          }
        }
      ]
    };
    this.alertController.create(opts).then(alert => {
      alert.present();
    });
  }
}
