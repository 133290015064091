import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-cadastrar',
  templateUrl: './cadastrar.component.html',
  styleUrls: ['./cadastrar.component.scss'],
})
export class CadastrarComponent implements OnInit {

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      nome: new FormControl("", [ Validators.required ]),
      whatsapp: new FormControl("", [ Validators.required ]),
      email: new FormControl("", [ Validators.required, Validators.email ]),
      cpf: new FormControl("", [ Validators.required ]),
    });
  }

  ngOnInit() {}

  cadastrar() {
    console.log("Fazer cadastro..");
  }

}
